export const validationRules = () => {
	return {
		required: [
			v => !!v || 'Field is required!',
		],
		requiredEmail: [
			v => !!v || 'Field is required!',
			//v => /.+@.+\..+/.test(v) || 'E-mail must be valid!',
			v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid!',
		],
		notRequiredEmail: [
			v => (!v || /.+@.+\..+/.test(v)) || 'E-mail must be valid!',
		],
	}
}