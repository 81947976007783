<template>
	<v-navigation-drawer
		fixed
		:clipped="$vuetify.breakpoint.mdAndUp"
		app
		:permament="returnHeight() && $vuetify.breakpoint.mdAndUp"
		:temporary="!returnHeight() && $vuetify.breakpoint.mdAndUp"
		:width="300"
		class=""
		v-model="menu"

	>
		<v-list dark>
			<div
				v-for="(m) in items"
				:key="m.id"
			>
				<sp-sub-nav
					v-if="m.children && m.children.length"
					:menu="m"
				/>

				<v-list-item
					ripple
					v-else
					@click="goToElement(m)"
				>
					<v-list-item-content>
						<v-list-item-title>
							{{ m.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			</div>
		</v-list>

	</v-navigation-drawer>
</template>

<script>


import SpSubNav from "@/components/template/SpSubNav";

function height() {
	return window.innerHeight > 600;
}

export default {
	name: 'SpNav',
	components: {SpSubNav},
	computed: {
		items() {
			return this.$store.getters['DASHBOARD_STORE/CATEGORIES'];
		},

		menu: {
			get() {
				return this.$store.getters['LAYOUT_NAV_STORE/MENU'];
			},
			set(value) {
				this.$store.commit('LAYOUT_NAV_STORE/MENU_SET', value)
			}
		},
	},
	props: [],
	data() {
		return {}
	},
	methods: {
		returnHeight() {
			return height();
		},
		goToElement(item) {
			if (this.$vuetify.breakpoint.mdAndDown) {
				this.menu = false;
			}

			this.$store.commit('DASHBOARD_STORE/GO_TO_SET', null)
			setTimeout(() => {
				this.$store.commit('DASHBOARD_STORE/GO_TO_SET', item.id)
			}, 50)

			this.$gtag.event(
				'Custom',
				{
					'klik': 'left-menu',
					'name': item.name,
				}
			);
		},
	},/*
	watch: {
		menu: function () {
			console.log(this.menu);
		}
	},*/
	created() {

	},
};
</script>
