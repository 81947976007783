<template>
	<v-dialog
		transition="dialog-top-transition"
		max-width="1200"
		v-model="dialog"
		@click:outside="close"
	>
		<template v-slot:activator="{ on }">
			<slot name="activator_btn" v-on="on"/>
		</template>
		<template>
			<v-form
				ref="form"
				lazy-validation
			>
				<v-card>
					<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center">
						<div>
							<v-icon size="28" color="black">far fa-bell</v-icon>
							Alerts:
						</div>
						<v-spacer/>
						<div class="" @click="close()">
							<v-btn
								icon
								text
							>
								<v-icon size="18">mdi-close</v-icon>
							</v-btn>
						</div>
					</v-card-title>
					<v-card-text>

						<div v-for="(item, index) in items" :key="index">
							<div class="d-flex">
								<v-spacer/>
								<div class="">{{ $dayjs.unix(item.created_at).format('YYYY-MM-DD HH:mm') }}</div>
							</div>
							<v-alert :outlined="!item.show" :type="item.type" v-if="item.type" v-html="item.text" />
							<div v-else v-html="item.text" class="border-1s pa-2"/>
						</div>

					</v-card-text>
				</v-card>
			</v-form>
		</template>
	</v-dialog>

</template>

<script>

export default {
	name: 'DialogAlerts',
	components: {},
	computed: {
		user() {
			return this.$store.getters['AUTH_STORE/USER'];
		},
		items() {
			return this.$store.getters['NOTIFICATION_STORE/ITEMS'];
		},
		waiting() {
			return this.$store.getters['NOTIFICATION_STORE/WAINING'];
		},

		dialog: {
			get() {
				return this.$store.getters['NOTIFICATION_STORE/DIALOG'];
			},
			set(value) {
				this.$store.commit('NOTIFICATION_STORE/DIALOG_SET', value);
			}
		},
	},
	data() {
		return {}
	},
	methods: {
		getItems() {
			this.$store.dispatch('NOTIFICATION_STORE/getAll',)
				.then(() => {
					this.loading = false;
				})
				.catch(err => {
					console.error(err)
					this.loading = false;
				});
		},
		checkNew() {
			if (!this.waiting) {
				this.getItems()
				this.$store.commit('NOTIFICATION_STORE/WAINING_SET', true)
				setTimeout(() => {
					this.$store.commit('NOTIFICATION_STORE/WAINING_SET', false)
					this.checkNew()
				}, 15000);
			}
		},

		showDialog() {
			this.getItems();
			this.dialog = true;
			this.$store.dispatch('NOTIFICATION_STORE/showed')
		},

		close() {
			this.$store.commit('NOTIFICATION_STORE/SHOW_SET')
			this.dialog = false;
		}
	},
	created() {
		this.checkNew();
	},
};
</script>
