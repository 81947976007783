<template>
	<v-app-bar
		fixed
		height="60"
		:clipped-left="$vuetify.breakpoint.mdAndUp"
	>
		<v-sheet color="transparent" height="100%" class="d-flex align-center px-1">
			<router-link :to="{name: 'Dashboard'}" id="logo">
				<v-img
					class="hidden-sm-and-down"
					alt=""
					width="243"
					src="/img/logo_dark.png"
				/>
				<v-img
					class="hidden-md-and-up hidden-xs-only"
					alt=""
					width="70"
					src="/img/small_logo_dark.png"
				/>
			</router-link>
			<v-spacer/>
			<v-btn icon rounded @click="changeMenu" v-if="spNav">
				<v-icon color="text" v-if="menu">mdi-arrow-left</v-icon>
				<v-icon color="text" v-else>mdi-arrow-right</v-icon>
			</v-btn>
		</v-sheet>


		<div class="text-h6 font-weight-300 hidden-sm-and-down">
			WOOKAH Wholesale Order Form
		</div>

		<v-spacer/>

		<v-btn
			icon
			class="pa-2"
			color="black"
			id="link_ikonka-koszyk"
			:to="{name: 'BasketIndex'}"
			:disabled="this.$route.name ==='BasketIndex'"
		>
			<v-icon size="20">fas fa-shopping-basket</v-icon>
		</v-btn>

		<dialog-send-message ref="dialogMessage">
			<template v-slot:activator_btn>
				<v-btn
					icon
					class="pa-2 messages-class"
					color="black"
					@click="$refs.dialogMessage.showDialog()"
					id="messages"
				>
					<v-icon size="20">fas fa-envelope</v-icon>
				</v-btn>
			</template>
		</dialog-send-message>

		<dialog-alerts ref="dialog">
			<template v-slot:activator_btn>
				<v-btn
					icon
					class="pa-2 "
					color="black"
					@click="$refs.dialog.showDialog()"
					id="alerts"
				>
					<v-badge
						color="red"
						:content="notifications"
						overlap
					>
						<v-icon size="20">far fa-bell</v-icon>
					</v-badge>
				</v-btn>
			</template>
		</dialog-alerts>

		<v-menu
			v-model="menuDropdown"
			:open-on-hover="false"
			:close-on-click="true"
			:close-on-content-click="true"
			:offset-y="true"
			class="ml-1"
			right
			content-class="main-menu--dropdown"
			nudge-width="80"
		>
			<template v-slot:activator="{ on }">
				<div
					class="h-100 d-flex align-center cursor--pointer ml-2"
					v-on="on"
					v-if="user"
				>
					{{ user.name }}
					<v-icon class="ml-1" color="black" size="24">mdi-chevron-down</v-icon>
				</div>
			</template>
			<v-list>
				<v-list-item :to="{name: i.action}" v-for="(i, index) in menuItems" :key="index" :id="i.id">
					<v-list-item-title class="d-flex pb-1" :class="i.classForText">
						<v-icon :color="i.colorForIcon ? i.colorForIcon: 'black'" size="16" class="mr-2">
							{{ i.icon }}
						</v-icon>
						{{ i.name }}
					</v-list-item-title>
				</v-list-item>

				<v-list-item :to="{name: 'SpecialOffers'}"  id="menu-special_offers" v-if="showSpecialOffer">
					<v-list-item-title class="d-flex pb-1 red--text">
						<v-icon color="red" size="16" class="mr-2">
							fas fa-piggy-bank
						</v-icon>
						Special Offers
					</v-list-item-title>
				</v-list-item>

				<v-list-item
					href="https://drive.google.com/drive/u/0/folders/1YM6krByjSKgDyHjNYrvMQxmV_Tq95A1N"
					target="_blank"
					id="menu-photos"
				>
					<v-list-item-title class="d-flex pb-1">
						<v-icon color="black" size="16" class="mr-2">
							fas fa-images
						</v-icon>
						Media Pack
					</v-list-item-title>
				</v-list-item>

				<v-list-item @click="logout()" id="menu-logout">
					<v-list-item-title class="d-flex pb-1">
						<v-icon color="black" size="16" class="mr-2">
							fas fa-power-off
						</v-icon>
						Logout
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

	</v-app-bar>
</template>

<script>
import DialogSendMessage from "@/components/template/Dialog/DialogSendMessage";
import DialogAlerts from "@/components/template/Dialog/DialogAlerts";

export default {
	name: 'SpAppBar',
	components: {DialogAlerts, DialogSendMessage},
	computed: {
		user() {
			return this.$store.getters['AUTH_STORE/USER'];
		},
		notifications() {
			return this.$store.getters['NOTIFICATION_STORE/ITEMS'].filter(i => i.show).length + '' ?? '0';
		},

		items() {
			return this.$store.getters['LAYOUT_NAV_STORE/ITEMS'];
		},
		menu() {
			return this.$store.getters['LAYOUT_NAV_STORE/MENU'];
		},
		width() {
			return this.menu ? 300 : 64;
		}
	},
	props: {
		spNav: {
			type: Boolean,
			required: false,
			default: true
		},
	},
	data() {
		return {
			menuDropdown: false,

			menuItems: [
				{
					name: 'Order Form',
					icon: 'far fa-list-alt',
					action: 'Dashboard',
					id: 'menu-order_form',
				},
				{
					name: 'Edit Profile',
					icon: 'far fa-user',
					action: 'ProfileIndex',
					id: 'menu-edit_profile',
				},
				{
					name: 'History',
					icon: 'fas fa-history',
					action: 'OrderHistoryIndex',
					id: 'menu-history',
				},
				{
					name: 'Help',
					icon: 'fas fa-info-circle',
					action: 'OtherHelp',
					id: 'menu-help',
				},
				{
					name: 'FAQ',
					icon: 'far fa-question-circle',
					action: 'OtherFaq',
					id: 'menu-faq',
				},
				{
					name: 'Dossier',
					icon: 'far fa-file-alt',
					action: 'OtherDossier',
					id: 'menu-dossier',
				},
				{
					name: 'Portfolio',
					icon: 'fas fa-camera',
					action: 'OtherPortfolio',
					id: 'menu-portfolio',
				},
				/*{
					name: 'Discount Bundles',
					icon: 'fas fa-cubes',
					action: 'OtherPromotion',
					id: 'menu-discount_bundles',
				},*/
			],
			showSpecialOffer: false,
		}
	},
	methods: {
		changeMenu() {
			this.$store.commit('LAYOUT_NAV_STORE/MENU_SET', !this.menu)
		},


		getSpecialOffer() {
			this.$store.dispatch('DASHBOARD_STORE/getSpecialOffer')
				.then(resp => {
					this.showSpecialOffer = !!resp.data.data;
				})
				.catch(err => {
					console.error(err)
					this.showSpecialOffer = false;
					this.dialog = false
				});
		},

		logout() {
			this.$swal({
				icon: 'warning',
				title: "Are you sure you want to logout?",
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				customClass: {
					confirmButton: 'v-btn v-btn--contained logout-yes',
					cancelButton: 'v-btn v-btn--contained logout-no',
				},
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('AUTH_STORE/logout');
				}
			});
		}
	},
	mounted() {
		process.nextTick(() => {
			this.getSpecialOffer();
		});
	},
};
</script>
