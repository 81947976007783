<template>
	<v-dialog
		transition="dialog-top-transition"
		max-width="800"
		v-model="dialog"
		@click:outside="close"
	>
		<template v-slot:activator="{ on }">
			<slot name="activator_btn" v-on="on"/>
		</template>
		<template>
			<v-form
				ref="form"
				lazy-validation
			>
				<v-card>
					<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center">
						<div>
							<v-icon size="28" color="black">fas fa-envelope</v-icon>
							Send Message To WOOKAH Team:
						</div>
						<v-spacer/>
						<div class="" @click="close()">
							<v-btn
								icon
								text
							>
								<v-icon size="18">mdi-close</v-icon>
							</v-btn>
						</div>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" class="d-flex align-start">
								<v-text-field
									v-model="title"
									label="Title*"
									outlined
									dense
									hide-details="auto"
									:rules="required"
								/>
							</v-col>
							<v-col cols="12" class="d-flex align-start">
								<v-textarea
									v-model="message"
									outlined
									label="Message*"
									rows="6"
									hide-details="auto"
									:rules="required"
								/>
							</v-col>

						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end pb-4 mt-2 px-4">
						<v-spacer/>
						<v-btn
							:loading="loading"
							color="success"
							class="px-8"
							@click="sendMessage()"
							id="message-send"
						>
							SEND
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</template>
	</v-dialog>

</template>

<script>
import {validationRules} from '@/helpers/validationRules'

export default {
	name: 'DialogSendMessage',
	components: {},
	computed: {},
	data() {
		return {
			...validationRules(),

			dialog: null,

			title: '',
			message: '',

			loading: false,
		}
	},
	methods: {
		showDialog() {
			this.dialog = true;
			this.$nextTick(() => this.$refs.form.resetValidation())
		},
		sendMessage() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.loading = true;
			this.$store.dispatch('SEND_MESSAGE_STORE_STORE/sendMessage', {
				title: this.title,
				message: this.message,
			})
				.then((resp) => {
					this.loading = false;
					if (resp.data.success) {
						this.clear();
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loading = false;
				});
		},
		clear() {
			this.title = '';
			this.message = '';
		},
		close() {
			this.clear();
			this.$refs.form.resetValidation()
			this.dialog = false;
		}
	},
	created() {
	},
};
</script>
