<template>
	<v-list-group
		:value="menu.value"
		prepend-icon=""
		sub-group
		no-action
		@click="menu.value = !menu.value"
	>
		<template v-slot:activator>
			<v-list-item-title>
				{{ menu.name }}
			</v-list-item-title>
			<v-list-item-icon class="v-icon--transform" :class="{'v-icon--rotate-180':menu.value }">
				<v-icon>mdi-chevron-up</v-icon>
			</v-list-item-icon>
		</template>

		<div
			v-for="m in menu.children"
			:key="m.id"
		>
			<sp-sub-nav
				v-if="m.children && m.children.length"
				:menu="m"
			/>

			<v-list-item
				ripple
				v-else
				@click="goToElement(m)"
			>
				<v-list-item-content>
					<v-list-item-title>
						{{ m.name }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</div>
	</v-list-group>

</template>

<script>
export default {
	name: 'SpSubNav',
	components: {},
	computed: {},
	props: [
		'menu'
	],
	data() {
		return {}
	},
	methods: {
		goToElement(item) {
			this.$store.commit('DASHBOARD_STORE/GO_TO_SET', null)
			setTimeout(() => {
				this.$store.commit('DASHBOARD_STORE/GO_TO_SET', item.id)
			}, 50)

			this.$gtag.event(
				'Custom',
				{
					'klik': 'left-menu',
					'name': item.name,
				}
			);
		},

	},
	created() {

	},
};
</script>
