<template>
	<div class="h-100">
		<sp-app-bar/>

		<sp-nav/>

		<transition
			name="fade"
			mode="out-in"
			:duration="500"
		>
			<v-main :style="mainStyles" class="">
				<router-view/>
			</v-main>
		</transition>
	</div>
</template>


<script>
import SpNav from "@/components/template/SpNav";
import SpAppBar from "@/components/template/SpAppBar";

export default {
	name: 'LayoutDefault',
	components: {SpAppBar, SpNav},
	computed: {
		mainStyles() {
			return this.$vuetify.breakpoint.lgAndUp ? 'margin-left: 0' : '';
		},
	},
	data: () => ({}),
};
</script>
